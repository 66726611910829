/**
 * This is a special page built due to a limitation with NextJS+ AMP
 *
 * Consider the following routes:
 *
 * /the-buzz/en
 * /the-buzz/some-blog
 *
 * In terms of code they will be represented as /the-buzz/[some_param]
 *
 * Basically a single page has to handle both. But we ONLY want amp for blog posts so how do we exclude languages?
 *
 * Here's where nginx comes into play, have a look at `nginx.conf` and search for `index-lang`. We rewrite all urls with just the language in them
 * to map to index-lang this way we get selective AMP and separation of languages
 */

export { default } from "components/the-buzz-theme/pages/index-page";
export { getServerSidePropsForIndexLang as getServerSideProps } from "lib/get-server-side-props";
